import React from 'react';
import { Dialog, DialogContent, makeStyles, Typography, DialogTitle, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import eventService from '../../services/event-service';
import EventTypes from '../../constants/event-types';

import CloseIcon from '@material-ui/icons/Close';

import RubricCard from './RubricCard';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '30vh',
  },
  projectProgressAreaContent: {},
  indicators: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {},
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  rubricsContainer: {
    minHeight: '2rem',
    display: 'grid',
    'grid-template-columns': 'repeat(3, 1fr)',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      'grid-template-columns': 'auto',
    },
  },
  rubricContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  noneText: {
    textAlign: 'center',
  },
}));

const ProjectRubricsArea = ({ rubrics = [] }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_PROJECT_RUBRICS_AREA_DIALOG, () => {
      setOpen(true);
    });
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.mainContainer}
      fullWidth
      maxWidth={window.screen.width <= 1280 ? 'md' : 'lg'}
    >
      <DialogTitle disableTypography>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.projectProgressAreaContent}>
        {rubrics.length ? (
          <div className={classes.indicators}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title}>Confira abaixo as rúbricas da obra:</Typography>
            </div>
            <div className={classes.rubricsContainer}>
              {rubrics.map((rubric, index) => (
                <div className={classes.rubricContainer} key={index}>
                  <RubricCard key={index} {...rubric} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Typography className={classes.noneText}>Sem informações</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProjectRubricsArea;
