/* eslint-disable no-unused-vars */
import { makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Bar, BarChart, ResponsiveContainer, Tooltip as ChartTooltip, XAxis, YAxis, ReferenceLine } from 'recharts';
import formatCurrency from '../../lib/format-currency';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.6em',
    minHeight: '4em',
  },
  progressIndicator: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      minHeight: '7em',
    },
  },
  progressIndicatorPaper: {
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
  },
  chartContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressIndicatorTextArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
  },
  progressIndicatorPercentText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '0.8em',
    marginLeft: theme.spacing(1),
  },
  progressIndicatorTitleText: {
    color: '#52575D',
    fontFamily: 'Montserrat',
    marginRight: theme.spacing(2),
  },
  progressIndicatorRightArea: {
    flex: 0.1,
  },
}));

const TranslatedAxis = {
  estimated: 'Orçado',
  consumed: 'Realizado',
  balance: 'Saldo',
};

const RubricCard = ({ name, estimated, consumed, balance }) => {
  const classes = useStyles();

  const data = [
    {
      name,
      [TranslatedAxis.estimated]: estimated,
      [TranslatedAxis.consumed]: consumed,
      [TranslatedAxis.balance]: balance,
    },
  ];
  const Chart = () => (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        // width={100}
        // height={100}
        data={data}
        layout='vertical'
        // margin={{
        //   top: 5,
        //   right: 30,
        //   left: 20,
        //   bottom: 5,
        // }}
      >
        {/* <CartesianGrid strokeDasharray='3 3' /> */}
        {/* <XAxis dataKey='name' /> */}
        <XAxis type='number' tick={false} axisLine={true} height={0} />
        <YAxis type='category' dataKey='name' tick={false} axisLine={false} width={0} />
        <ReferenceLine x={0} stroke='#000000' />
        <ChartTooltip formatter={(tooltipValue, tooltipName) => [formatCurrency(tooltipValue), tooltipName]} />
        {/* <Legend /> */}
        <Bar dataKey={TranslatedAxis.estimated} fill='#aaa9b8' />
        <Bar dataKey={TranslatedAxis.consumed} fill='#22234d' />
        <Bar dataKey={TranslatedAxis.balance} fill={balance >= 0 ? '#4072fb' : '#832828'} />
      </BarChart>
    </ResponsiveContainer>
  );

  const Indicator = () => (
    <div className={classes.rootContainer}>
      <div className={classes.chartContainer}>
        <Chart />
      </div>
      <div className={classes.progressIndicatorTextArea} style={{ flex: 1 }}>
        <Typography
          className={classes.progressIndicatorTitleText}
          variant={isMobile ? 'body2' : 'body1'}
          style={{ fontSize: isMobile ? 10 : '' }}
        >
          {name}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.progressIndicator}>
      <Paper className={classes.progressIndicatorPaper} elevation={3} style={{ width: isMobile ? 110 : 200 }}>
        <Indicator />
      </Paper>
    </div>
  );
};

export default RubricCard;
