const EventTypes = {
  OPEN_INDICATION_DIALOG: 'OPEN_INDICATION_DIALOG',
  OPEN_INVESTMENT_SIMULATOR: 'OPEN_INVESTMENT_SIMULATOR',
  OPEN_IMAGES_DIALOG: 'OPEN_IMAGES_DIALOG',
  OPEN_PROJECT_PROGRESS_AREA_DIALOG: 'OPEN_PROJECT_PROGRESS_AREA_DIALOG',
  OPEN_PROJECT_RESULTS_DIALOG: 'OPEN_PROJECT_RUBRICS_AREA_DIALOG',
  OPEN_OPTIONS_MENU: 'OPEN_OPTIONS_MENU',
  OPEN_DOCUMENTS_DIALOG: 'OPEN_DOCUMENTS_DIALOG',
  LOAD_VIEW: 'LOAD_VIEW',
};

export default EventTypes;
