import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import {
  makeStyles,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Paper,
  Slide,
  Typography,
  Tooltip,
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import currencyFormatter from 'currency-formatter';

import Chart from '../Charts';
import ImagesCarousel from '../Images-carousel/ImagesCarousel';

import ProjectIcon from '@material-ui/icons/AccountTreeOutlined';
import NFIcon from '@material-ui/icons/ReceiptOutlined';
import DocumentIcon from '@material-ui/icons/DescriptionOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ChartIcon from '@material-ui/icons/PieChartOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import dataService from '../../services/data-service';
import eventService from '../../services/event-service';
import Documents from '../Documents/Documents';
import EventTypes from '../../constants/event-types';
import ProjectInputIndicator from '../Construction-progress/ProjectInputIndicator';
import ProjectProgressArea from '../Project-progress-area/ProjectProgressArea';
import { isMobile } from 'react-device-detect';
import MobileInvestor from './MobileInvestor';
import UserViewController from '../../controllers/UserViewController';
import UserViews from '../../constants/user-views';
import ProjectRubricsArea from '../Project-rubrics-area';

const useStyles = makeStyles((theme) => ({
  investor: {
    minHeight: '75vh',
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    marginTop: theme.spacing(5),
  },
  leftArea: {
    flex: 0.14,
  },
  rightArea: {
    flex: 0.14,
  },
  centerArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  selectedProjectArea: {
    display: 'flex',
    // marginTop: theme.spacing(1),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  projectSelect: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    boxShadow: '10px 10px 15px -14px rgba(0,0,0,0.75)',
    height: 5,
    minWidth: 150,
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:before': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
  projectSelectIcon: {
    fill: theme.palette.secondary.main,
  },
  projectSelectMenu: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#101257',
    color: '#FFF',
    marginTop: theme.spacing(4.2),
  },
  projectSelectMenuText: {
    fontFamily: 'Montserrat',
    fontSize: 15,
  },
  projectSelectMenuListItem: {
    flex: 1,
  },
  projectSelectTextField: {
    display: 'flex',
    alignItems: 'center',
  },
  projectSelectTextFieldIcon: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  projectButtonsArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      flexDirection: 'column',
    },
  },
  projectButtons: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  button: {
    backgroundColor: '#FFF',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    // padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  buttonText: {
    fontFamily: 'Montserrat',
  },

  charts: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  projectInputArea: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  projectInputTextArea: {
    flex: 0.7,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  projectInputText: {
    fontFamily: 'Montserrat',
    fontWeight: '1000',
    color: theme.palette.primary.main,
  },
  projectInputSubtext: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: '#52575D',
  },
  projectInputIndicator: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
      alignItems: 'center',
    },
  },
  projectInputRightArea: {
    flex: 0.5,
  },
  chartArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  projectChartPaper: {
    padding: theme.spacing(1),
    minHeight: '40vh',
  },
  projectChart: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      width: 300,
      height: 300,
      marginTop: theme.spacing(3),
    },
  },
  projectChartCenterSpace: {
    flex: 0.05,
  },
  progressIndicator: {
    flex: 1,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  changingProjectArea: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Investor = ({ history }) => {
  const [projects, setProjects] = useGlobal('projects'); //eslint-disable-line
  const [user] = useGlobal('user');
  const [selectedProject, setSelectedProject] = useGlobal('selectedProject');

  const [isLoading, setIsLoading] = useState(false);
  const [isChangingProject, setIsChangingProject] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    if (user.type && user.type !== 'INVESTOR' && user.type !== 'INVESTOR/REFERRER') {
      history.push('/');
    }
    if (!user.id) {
      history.push('/');
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    UserViewController.loadView(UserViews.INVESTOR);
    fetchAllProjects();
  }, []); //eslint-disable-line

  const fetchAllProjects = async () => {
    setIsLoading(true);
    try {
      const projects = await dataService.fetchAllProjects();
      setProjects(projects);
      if (projects.length) {
        const selectedProject = await dataService.fetchProject(projects[0].id);
        setSelectedProject({ ...projects[0], ...selectedProject });
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const onSelectChange = async (event) => {
    setIsChangingProject(true);
    let projectInfo = projects.find((project) => project.id === event.target.value);
    try {
      const selectedProject = await dataService.fetchProject(event.target.value);
      projectInfo = { ...projectInfo, ...selectedProject };
    } catch (err) {
      console.error(err);
    }
    setSelectedProject(projectInfo);
    setIsChangingProject(false);
  };

  const onMobileSelectChange = async (updateProject) => {
    setIsChangingProject(true);
    let projectInfo = projects.find((project) => project.id === updateProject.id);
    try {
      const selectedProject = await dataService.fetchProject(updateProject.id);
      projectInfo = { ...projectInfo, ...selectedProject };
    } catch (err) {
      console.error(err);
    }
    setSelectedProject(projectInfo);
    // debugger;
    setIsChangingProject(false);
  };

  function ProjectButton({ title, tooltip, eventType, eventParams, icon }) {
    return (
      <Tooltip title={tooltip}>
        <Button
          variant='contained'
          startIcon={icon}
          className={classes.button}
          onClick={() => eventService.emitEvent(eventType, eventParams)}
          disabled={isChangingProject}
        >
          <Typography className={classes.buttonText} variant='body2'>
            {title}
          </Typography>
        </Button>
      </Tooltip>
    );
  }

  return isLoading ? (
    <div className={classes.progressIndicator}>
      <CircularProgress />
    </div>
  ) : !isMobile ? (
    <div className={classes.investor}>
      <div className={classes.leftArea}></div>
      <div className={classes.centerArea}>
        <div className={classes.topButtons}>
          <div className={classes.selectedProjectArea}>
            <div>
              <Select
                variant='outlined'
                value={selectedProject?.id || -1}
                onChange={onSelectChange}
                classes={{
                  root: classes.projectSelect,
                  outlined: classes.projectSelect,
                  icon: classes.projectSelectIcon,
                }}
                MenuProps={{
                  classes: {
                    paper: classes.projectSelectMenu,
                    list: classes.projectSelectMenuListItem,
                  },
                }}
                style={{ backgroundColor: '#0b0c32' }}
              >
                {projects?.length ? (
                  projects.map((project, index) => (
                    <MenuItem
                      value={project.id}
                      key={index}
                      style={{ display: project.id === selectedProject.id ? 'none' : '' }}
                    >
                      <div className={classes.projectSelectTextField}>
                        {project.id === selectedProject.id ? (
                          <div className={classes.projectSelectTextFieldIcon}>
                            <HomeIcon />
                          </div>
                        ) : null}
                        <Typography className={classes.projectSelectMenuText}>{project?.name}</Typography>
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={-1}>
                    <div className={classes.projectSelectTextField}>
                      {!selectedProject ? (
                        <div className={classes.projectSelectTextFieldIcon}>
                          <HomeIcon />
                        </div>
                      ) : null}
                      <Typography className={classes.projectSelectMenuText}>Sem projetos</Typography>
                    </div>
                  </MenuItem>
                )}
              </Select>
            </div>
          </div>
          <div className={classes.projectButtonsArea}>
            <div className={classes.projectButtons}>
              <ProjectButton
                title='NFs'
                tooltip='Notas fiscais'
                icon={<NFIcon />}
                eventType={EventTypes.OPEN_DOCUMENTS_DIALOG}
                eventParams={['nfs']}
              />
              <ProjectButton
                title='Projetos'
                tooltip='Projetos'
                icon={<ProjectIcon />}
                eventType={EventTypes.OPEN_DOCUMENTS_DIALOG}
                eventParams={['projects']}
              />
              <ProjectButton
                title='Documentos'
                tooltip='Documentos'
                icon={<DocumentIcon />}
                eventType={EventTypes.OPEN_DOCUMENTS_DIALOG}
                eventParams={['documents']}
              />
              <ProjectButton
                title='Galeria'
                tooltip='Galeria de Mídias'
                icon={<PhotoLibraryIcon />}
                eventType={EventTypes.OPEN_IMAGES_DIALOG}
              />
              <ProjectButton
                title='Cronogramas'
                tooltip='Cronogramas'
                icon={<ChartIcon />}
                eventType={EventTypes.OPEN_PROJECT_PROGRESS_AREA_DIALOG}
              />
              <ProjectButton
                title='Rúbricas'
                tooltip='Rúbricas'
                icon={<AttachMoneyIcon />}
                eventType={EventTypes.OPEN_PROJECT_RUBRICS_AREA_DIALOG}
              />
            </div>
          </div>
        </div>
        {!isChangingProject ? (
          <div className={classes.charts}>
            <div className={classes.projectInputArea}>
              <div className={classes.projectInputTextArea}>
                <Typography className={classes.projectInputText} variant='h5'>
                  Aporte Financeiro:
                </Typography>
                <Typography className={classes.projectInputSubtext} variant='body1'>
                  Valor total estimado:{' '}
                  {currencyFormatter.format(selectedProject?.totalInput, {
                    code: 'BRL',
                  })}
                </Typography>
              </div>
              <div className={classes.projectInputIndicator}>
                <ProjectInputIndicator
                  percent={
                    selectedProject?.currentInput && selectedProject?.totalInput
                      ? (100 * selectedProject?.currentInput) / selectedProject?.totalInput
                      : 0
                  }
                  value={selectedProject?.currentInput}
                />
              </div>
              <div className={classes.projectInputRightArea}></div>
            </div>
            {selectedProject ? (
              <div className={classes.chartArea}>
                <Slide direction='left' in={true} timeout={{ enter: 1 }}>
                  <div className={classes.projectChart}>
                    <Paper elevation={5} className={classes.projectChartPaper} square={false}>
                      <Chart
                        chartTitle={'Cronograma físico-financeiro'}
                        data={selectedProject?.charts?.financialPhysicalSchedule}
                        xPercent
                        chartType='area'
                        yMoney
                        chartColors={['#39B6FF']}
                        yMax={selectedProject.currentInput}
                        width={(30 * window.screen.width) / 100}
                        height={(28 * window.screen.height) / 100}
                      />
                    </Paper>
                  </div>
                </Slide>
                <div className={classes.projectChartCenterSpace}></div>
                <Slide direction='left' in={true} timeout={{ enter: 1 }}>
                  <div className={classes.projectChart}>
                    <Paper elevation={5} className={classes.projectChartPaper} square={false}>
                      <Chart
                        chartTitle={'Cronograma da obra'}
                        data={selectedProject?.charts?.projectSchedule}
                        yPercent
                        chartType='area'
                        chartColors={['#fdd365']}
                        width={(30 * window.screen.width) / 100}
                        height={(28 * window.screen.height) / 100}
                      />
                    </Paper>
                  </div>
                </Slide>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={classes.changingProjectArea}>
            <CircularProgress />
          </div>
        )}
        <div>
          <Documents
            documents={selectedProject?.documents?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
            nfs={selectedProject?.nfs?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
            projects={selectedProject?.projects?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
          />
          <ImagesCarousel images={selectedProject?.images || []} />
          <ProjectProgressArea phasesProgress={selectedProject?.phasesProgress} />
          <ProjectRubricsArea rubrics={selectedProject?.rubrics} />
        </div>
      </div>
      <div className={classes.rightArea}></div>
    </div>
  ) : (
    <MobileInvestor
      projects={projects}
      selectedProject={selectedProject}
      setSelectedProject={onMobileSelectChange}
      documents={selectedProject?.documents}
      isChangingProject={isChangingProject}
    />
  );
};

export default withRouter(Investor);
