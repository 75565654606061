import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Slide,
  IconButton,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from '@material-ui/core';

import ProjectIcon from '@material-ui/icons/AccountTreeOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import NFIcon from '@material-ui/icons/ReceiptOutlined';
import DocumentIcon from '@material-ui/icons/DescriptionOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryOutlined';
import ChartIcon from '@material-ui/icons/PieChartOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import eventService from '../../../services/event-service';
import EventTypes from '../../../constants/event-types';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandButton: {
    color: theme.palette.primary.main,
  },
  projectSelectExpansionPanel: {
    padding: 0,
    height: 20,
  },
  projectSelectContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  projectSelectContentIcon: {
    flex: 0.3,
  },
  projectSelectContentTextArea: {
    flex: 1,
  },
  selectedProjectText: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
  },
  listItem: {
    display: 'flex',
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: 'flex-end',
    borderBottom: '1px solid #ECECEC',
  },
  listItemContent: {
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
  },
  listItemContentIcon: {
    flex: 0.3,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listItemContentTextArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listItemContentText: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
  },
  '@global .dialogList div': {
    justifyContent: 'start',
  },
}));

const OptionsMenu = ({ projects, selectedProject, setSelectedProject }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [menuOptions] = useState([
    {
      name: 'Notas fiscais',
      icon: <NFIcon />,
      onClick: () => eventService.emitEvent(EventTypes.OPEN_DOCUMENTS_DIALOG, ['nfs']),
    },
    {
      name: 'Projetos',
      icon: <ProjectIcon />,
      onClick: () => eventService.emitEvent(EventTypes.OPEN_DOCUMENTS_DIALOG, ['projects']),
    },
    {
      name: 'Documentos',
      icon: <DocumentIcon />,
      onClick: () => eventService.emitEvent(EventTypes.OPEN_DOCUMENTS_DIALOG, ['documents']),
    },
    {
      name: 'Galeria de Mídias',
      icon: <PhotoLibraryIcon />,
      onClick: () => eventService.emitEvent(EventTypes.OPEN_IMAGES_DIALOG),
    },
    {
      name: 'Cronogramas',
      icon: <ChartIcon />,
      onClick: () => eventService.emitEvent(EventTypes.OPEN_PROJECT_PROGRESS_AREA_DIALOG),
    },
    {
      name: 'Rúbricas',
      icon: <AttachMoneyIcon />,
      onClick: () => eventService.emitEvent(EventTypes.OPEN_PROJECT_RUBRICS_AREA_DIALOG),
    },
  ]);

  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_OPTIONS_MENU, () => {
      setOpen(true);
    });
  }, []);

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Slide direction='left' in={open}>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle disableTypography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon size='large' />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            <List className='dialogList'>
              <ListItem button alignItems='flex-end'>
                <Accordion elevation={0} style={{ borderBottom: '1.5px solid #ECECEC' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.expandButton} />}
                    className={classes.projectSelectExpansionPanel}
                  >
                    <div className={classes.projectSelectContent}>
                      <div className={classes.projectSelectContentIcon}>
                        <HomeIcon />
                      </div>
                      <div className={classes.projectSelectContentTextArea}>
                        <Typography variant='h6' className={classes.selectedProjectText}>
                          {selectedProject?.name}
                        </Typography>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {projects?.map?.((project, index) =>
                        project === selectedProject ? null : (
                          <ListItem
                            button
                            key={index}
                            onClick={() => {
                              onClose();
                              setSelectedProject(project);
                            }}
                          >
                            <ListItemText>{project.name}</ListItemText>
                          </ListItem>
                        )
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </ListItem>
              {menuOptions.map((option, index) => (
                <ListItem
                  button
                  className={classes.listItem}
                  key={index}
                  onClick={() => {
                    option.onClick();
                    onClose();
                  }}
                >
                  <div className={classes.listItemContent}>
                    <div className={classes.listItemContentIcon}>{option.icon}</div>
                    <div className={classes.listItemContentTextArea}>
                      <ListItemText className={classes.listItemContentTextArea}>
                        <Typography variant='body2' className={classes.listItemContentText}>
                          {option.name}
                        </Typography>
                      </ListItemText>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </Slide>
  );
};

export default OptionsMenu;
